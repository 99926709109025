
























































import { prefix, privacy } from '@/config/global';
import { Vue, Component, Watch } from 'vue-property-decorator';
import globalCard from '@/components/GlobalCard/index.vue';
import {
  CardCofing,
  CardCofingHelp,
  CustomerParams,
  CustomerParamsStartEnd,
  FlowNumS2,
} from '@/types/store';
import { flowAnalysis, numRankTrend } from '@/utils/echarts/echarts';
import { passengerFlowData, customerOriginAnalysis } from '@/services/api';
import helpCircleFilled from '@/components/HelpCircleFilled/index.vue';
import { computePassengerFlowProfile, dateArys } from '@/components/index';
import { Option } from 'view-design';
import tdesignFooter from '@/components/GlobalFooter/index.vue';
import spinCard from '@/components/SpinCard/index.vue';
/* eslint-disable @typescript-eslint/naming-convention */

@Component({
  components: {
    globalCard,
    helpCircleFilled,
    tdesignFooter,
    spinCard,
  },
})
export default class SummaryStatistics extends Vue {
  prefix: string = prefix;
  privacy: string = privacy;
  cardCofing: Array<CardCofing> = [];
  activePassengerData = {};
  cardType = 0;
  helpSize = '19';
  cardInfoShow = 1;
  cardInfoHide = 0;
  // date = '2021-06-09';
  beforeDate = '2021-06-08';
  comparedFlow!: any;
  dataStatus!: string;
  duration = this.$store.state.mall.duration;
  mall_id = this.$store.state.mall.mallId;
  date = this.$store.state.mall.date;
  reFresh = true;
  compoentKey = 0;
  cardShow = true;
  datas: any;
  customerDatas: any;
  dateAry: string[] = [];
  todayData!: FlowNumS2;
  cardCofingHelp: Array<CardCofingHelp> = [];
  showYesterday = true;
  nodataCard = {
    id: 1,
    title: '暂无数据',
    titleInfo: this.privacy,
    helpSize: '14',
    cardInfoStatus: this.cardInfoShow,
  };
  $dayjs: any;
  todayPoint = 'passenger_flow_statistics_today_enter';

  created(): void {
    const option1 = {};
    const option2 = {};
    const option3 = {};
    this.cardCofing = [
      {
        id: 1,
        title: '客流趋势',
        titleInfo: '',
        cardInfoStatus: this.cardInfoHide,
        helpSize: this.helpSize,
        option: option1,
        cardType: 0,
        show: true,
        buriedPoint: 'Passenger_flow_trend_enter',
      },
      {
        id: 2,
        title: '新客趋势',
        titleInfo:
          '近31天内，新客人数和新客占比（新客人数/全部到访人数）的变化',
        cardInfoStatus: this.cardInfoShow,
        helpSize: this.helpSize,
        option: option2,
        cardType: 0,
        show: true,
        buriedPoint: 'newcomer_trend_enter',
      },
      {
        id: 3,
        title: '到访人数排名趋势',
        titleInfo: '近31天内，该区域顾客人数在本市的排名变化',
        cardInfoStatus: this.cardInfoShow,
        helpSize: this.helpSize,
        option: option3,
        option2: option3,
        cardType: 0,
        show: true,
        buriedPoint: 'visitor_ranking_trend_enter',
      },
    ];
    this.cardCofingHelp = [
      {
        id: 1,
        title: '累计到访人次',
        titleInfo: '当天顾客到访过的次数，一个顾客可到访多次',
        helpSize: '14',
        cardInfoStatus: this.cardInfoShow,
        data: '0',
        compared: '0',
      },
      {
        id: 2,
        title: '累计到访人数',
        titleInfo: '当天到访的顾客总数',
        helpSize: '14',
        cardInfoStatus: this.cardInfoShow,
        data: '0',
        compared: '0',
      },
      {
        id: 3,
        title: '人均到访频次',
        titleInfo: '当天到访的顾客人次/人数',
        helpSize: '14',
        cardInfoStatus: this.cardInfoShow,
        data: '0',
        compared: '0',
      },
      {
        id: 4,
        title: '新客人数',
        titleInfo: '指近三个月，首次到访的顾客',
        helpSize: '14',
        cardInfoStatus: this.cardInfoShow,
        data: '0',
        compared: '0',
      },
      {
        id: 5,
        title: '新客流占比',
        titleInfo: '新客占全部到访人数的比值',
        helpSize: '14',
        cardInfoStatus: this.cardInfoShow,
        data: '0',
        compared: '0',
      },
    ];
  }

  async pageHandler(): Promise<void> {
    this.$stores.global.setDownAble(false); // 数据全部加载完全后才允许下载
    this.cardShow = true;
    this.cardCofing.forEach((item: CardCofing, index: number) => {
      this.cardCofing[index].show = true;
    });
    // this.date = this.$store.state.mall.dayDate;
    // eslint-disable-next-line prefer-destructuring
    this.date = this.$store.state.mall.rangeDate.split(',')[1];
    const dateStart = this.$store.state.mall.rangeDate.split(',')[0];
    this.mall_id = this.$store.state.mall.mallId;
    this.duration = 'd';
    // this.mall_id = '5665';
    const beforeDate = this.$dayjs(this.date)
      .subtract(1, 'day')
      .format('YYYY-MM-DD');
    this.beforeDate = String(beforeDate);
    this.dateAry = dateArys(this.date, 31);
    const params: CustomerParamsStartEnd = {
      duration: this.duration,
      mall_id: this.mall_id,
      date_ary: this.dateAry,
      date_start: dateStart,
      date_end: this.date,
    };
    // const dateStr = `${this.date} 00:00:00`;
    const beforeDateStr = `${this.beforeDate} 00:00:00`;
    const datas = await passengerFlowData(params);
    const flowData = datas.data || {};
    this.datas = Object.values(flowData);
    // this.todayData = flowData[dateStr];
    this.todayData = datas.sumObj;
    const todayFlow = this.todayData.flow;
    const todayPercent = this.todayData.percent;
    const todayNewNum = this.todayData.new_num;
    const todayNewPercent = this.todayData.new_percent;
    const todayNum = this.todayData.num;
    this.showYesterday = dateStart === this.date;
    const beforeData: FlowNumS2 = flowData[beforeDateStr] || {};
    const beforeFlow = beforeData.flow;
    const beforePercent = beforeData.percent;
    const beforeNewNum = beforeData.new_num;
    const beforeNewNumPercent = beforeData.new_percent;
    const beforeNum = beforeData.num;
    const option1 = await this.getPassengerFlowOption(1, datas);

    this.cardCofing[0].option = option1;
    this.cardCofing[0].show = false;
    const option2 = await this.getPassengerFlowOption(2, datas);
    this.cardCofing[1].option = option2;
    this.cardCofing[1].show = false;
    this.cardCofingHelp = [
      {
        id: 1,
        title: '累计到访人次',
        titleInfo: '当天顾客到访过的次数，一个顾客可到访多次',
        helpSize: '14',
        cardInfoStatus: this.cardInfoShow,
        data: this.todayData.flow,
        compared: computePassengerFlowProfile(beforeFlow, todayFlow),
      },
      {
        id: 2,
        title: '累计到访人数',
        titleInfo: '当天到访的顾客总数',
        helpSize: '14',
        cardInfoStatus: this.cardInfoShow,
        data: this.todayData.num,
        compared: computePassengerFlowProfile(beforeNum, todayNum),
      },
      {
        id: 3,
        title: '人均到访频次',
        titleInfo: '当天到访的顾客人次/人数',
        helpSize: '14',
        cardInfoStatus: this.cardInfoShow,
        data: this.todayData.percent,
        compared: computePassengerFlowProfile(beforePercent, todayPercent, 1),
      },
      {
        id: 4,
        title: '新客人数',
        titleInfo: '指近三个月，首次到访的顾客',
        helpSize: '14',
        cardInfoStatus: this.cardInfoShow,
        data: this.todayData.new_num,
        compared: computePassengerFlowProfile(beforeNewNum, todayNewNum),
      },
      {
        id: 5,
        title: '新客流占比',
        titleInfo: '新客占全部到访人数的比值',
        helpSize: '14',
        cardInfoStatus: this.cardInfoShow,
        data: this.todayData.new_percent,
        compared: computePassengerFlowProfile(
          beforeNewNumPercent,
          todayNewPercent,
        ),
      },
    ];
    this.cardShow = false;
    const customerDatas = await customerOriginAnalysis(params);
    const option3 = await this.getCustomerAnalysis(params, customerDatas);
    this.cardCofing[2].option = option3;
    this.cardCofing[2].show = false;
    this.customerDatas = customerDatas.datas;
    this.$stores.global.setDownAble(true); // 数据全部加载完全后允许下载
  }
  @Watch('$store.state.mall', { deep: true })
  changeMall(): void {
    this.pageHandler();
  }

  @Watch('$store.state.global.downloadStatus')
  changeExport(): void {
    const status = this.$store.state.global.downloadStatus;
    if (status === true) {
      const { customerDatas, datas: flowData } = this;
      const data = flowData.map((item: any, index: number) => ({
        ...item,
        ...customerDatas[index],
      }));
      const sheetName = this.$route.name;
      const sheetHeader = [
        '日期',
        '到访人次',
        '到访人数',
        '人次/人数比',
        '新客流人数',
        '新客流占比',
        '客流量排名',
      ];
      const sheetFilter = [
        'time',
        'flow',
        'num',
        'percent',
        'new_num',
        'new_percent',
        'rank',
      ];
      const sheetData = data;
      const columnWidths: string[] = [];
      sheetHeader.forEach(() => {
        const lth = '5';
        columnWidths.push(lth);
      });
      const datas = [
        {
          sheetData,
          sheetName,
          sheetHeader,
          sheetMultiHeader: [['数据项:客流统计']],
          sheetFilter,
        },
      ];
      this.$root.$data.event.$emit('getDataS', datas);
    }
  }

  buriedPointHandler(buriedPoint: string): void{
    this.$beaconAction.onUserAction(buriedPoint, {});
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async getCustomerAnalysis(
    params: CustomerParams,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    data: any,
  ): Promise<Option> {
    const { times, sums, ranks, dataObj } = data;
    const option = numRankTrend(times, ranks, sums, dataObj);
    return option;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async getPassengerFlowOption(type: number, datas: any): Promise<Option> {
    const {
      times,
      percents,
      flows,
      nums,
      newPercents,
      newNums,
      oldNums,
      dataObj,
    } = datas;
    let legendData;
    let option;
    if (type === 1) {
      // 客流趋势
      legendData = [
        {
          name: '人次',
        },
        {
          name: '人数',
        },
        {
          name: '人均到访频次',
        },
      ];
      option = flowAnalysis(
        times,
        percents,
        flows,
        nums,
        legendData,
        dataObj,
        1,
        1,
        false,
        'auto',
        '2',
      );
    } else {
      // 新客趋势
      legendData = [
        {
          name: '新客人数',
        },
        {
          name: '老客人数',
        },
        {
          name: '新客占比',
        },
      ];
      option = flowAnalysis(
        times,
        newPercents,
        newNums,
        oldNums,
        legendData,
        dataObj,
      );
    }
    return option;
  }
}
